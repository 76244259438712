import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const KreditAbgelehnt = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Kredit abgelehnt? – Woran kann es liegen?" showCalc={false} />
            <Article>
                <h2>Woran es liegen kann, dass dein Kredit abgelehnt wurde</h2>
                <p>
                    Du hast gespart, dich informiert und endlich einen Antrag gestellt – und dann: abgelehnt. Auf den
                    ersten Blick können einem die Gründe für einen abgelehnten Kreditantrag manchmal unklar erscheinen.
                    Vor einigen Jahren hätte es unter den gleichen Bedingungen doch funktioniert! Woran kann das liegen?
                </p>
                <p>
                    Kreditinstitute haben behördliche Vorgaben und interne Vergaberichtlinien, die sich im Laufe der
                    Zeit ändern können. In diesem Artikel geht es darum, welche Mindestkriterien bei der Kreditvergabe
                    momentan zu erfüllen sind und was du im Fall, dass dein Kredit abgelehnt wurde, machen kannst.
                </p>
                <p>
                    <strong>Wichtig:</strong> Ein abgelehnter Kreditantrag ist noch kein Grund, deinen Traum von der
                    eigenen Immobilie wieder aufzugeben. Es lässt sich oft noch eine sinnvolle{" "}
                    <Link to="/artikel/kredit-tipps/" target="_blank" rel="noreferrer noopener">
                        Lösung
                    </Link>{" "}
                    finden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Gründe für einen abgelehnten Kreditantrag</h2>
                <p>
                    Leider kann es vorkommen, dass dein Antrag auf einen Kredit abgelehnt wird. Sollte das der Fall
                    sein, so hat es nicht selten folgende Gründe, die für die meisten Kreditinstitute als
                    Ausschlusskriterien fungieren:
                </p>
                <ul>
                    <li>ein zu niedriges oder unregelmäßiges Einkommen</li>
                    <li>ein zu hoher Anteil deines Einkommens ist variabel</li>
                    <li>dein Dienstverhältnis ist befristet</li>
                    <li>du verfügst über eine zu geringe Summe an Eigenmitteln</li>
                    <li>es bestehen bereits Kreditverträge über größere Summen oder du bist überschuldet</li>
                    <li>du bist nach Ermessen des Kreditinstituts zu alt bzw. zu jung</li>
                    <li>
                        Einträge bei Kreditauskunftstellen sprechen gegen dich (z.B. KSV 1870, CRIF, Bisnode Austria)
                    </li>
                </ul>
                <p>
                    Diese Punkte zu kennen, hilft dir im Vorhinein eine Ablehnung deines Kreditantrages zu verhindern.
                    Du solltest dir bestenfalls bereits vor dem Kreditantrag darüber klar sein, wieviel du dir
                    eigentlich leisten kannst. Das ist manchmal gar nicht so einfach, weshalb wir ein Tool entwickelt
                    haben mit dem du dir schnell einen Überblick über die Situation verschaffen kannst.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h3>Kredit abgelehnt – Was du jetzt tun kannst</h3>
                <p>
                    Wenn dein Kredit abgelehnt wurde, dann solltest du versuchen herauszufinden, unter welchen
                    Bedingungen du ihn bewilligt bekommen würdest. Manchmal handelt es sich bloß um Kleinigkeiten, die
                    sich schnell aus dem Weg räumen lassen. Du solltest dich nicht ärgern oder die Hoffnung aufgeben –
                    ein Gespräch mit einem Finanzexperten oder einer Finanzexpertin kann dir bereits weiterhelfen. Sie
                    haben einen Überblick über den Markt und können einschätzen, wieviel du dir leisten kannst und
                    welche Banken und Bausparkassen sich für deine Finanzierung am besten eignen.
                </p>
                <h3>Eigenmittel erhöhen</h3>
                <ul>
                    <li>
                        Hast du vielleicht Wertpapierdepots, Gold-/Silberreserven oder eine Lebensversicherung? Wenn ja,
                        solltest du diese liquidieren, um dadurch mehr Eigenmittel zu erhalten.
                    </li>
                    <li>
                        Gibt es eigene Liegenschaften?Diese können im Zuge einer Simultanhypothek dienen. Durch eine
                        höhere Besicherung infolge weiterer eingebrachter Immobilien könnte sich der{" "}
                        <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                            Kredit
                        </Link>{" "}
                        ausgehen.
                    </li>
                    <li>
                        Besitzt du Antiquitäten oder andere Wertgegenstände? Manchmal kann der Verkauf von
                        Wertgegenständen, an die du vorher nicht gedacht hast, deiner Finanzierung dienen.
                    </li>
                </ul>
                <h3>Zusätzliche Einkommensquellen erschließen</h3>
                <p>
                    Hast du genug Eigenmittel, doch dein{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    wird trotzdem abgelehnt, solltest du vermutlich am besten neue Einkommensquellen suchen. Du könntest
                    ungenutzte Räume vermieten, falls du welche besitzt. Außerdem kannst du neben deinem Hauptberuf
                    einen geringfügig bezahlten Nebenjob annehmen. Dadurch würdest du sowohl dein Einkommen als auch
                    deine Bonität erhöhen.
                </p>
                <h3>Anderes Kreditinstitut aufsuchen</h3>
                <p>
                    Oft werden{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredite
                    </Link>{" "}
                    abgelehnt, weil die Rate schlicht zu hoch ist. Lass dich von Expert:innen unterstützen, die dir
                    helfen die richtigen Banken und Bausparkassen in Österreich zu finden, deren Kriterien für die
                    Kreditvergabe zu dir passt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <h3>Gibt es Bürgen oder Familienmitglieder, die helfen können?</h3>
                <p>
                    Wurde dein Kredit überall abgelehnt und siehst du in den vorherigen Ratschlägen keinen, der dir
                    weiterhilft, dann musst du dir Unterstützung von Menschen aus deinem engeren Umfeld suchen.
                    Ehepartner:innen und Eltern können für deinen Kredit als Bürgen dienen. Vielleicht hilft dir deine
                    Familie dabei, dir deinen Wunsch nach einem eigenen Haus zu erfüllen.
                </p>
                <p>
                    Von Krediten durch Privatpersonen aus dem Internet raten wir jedoch dringend ab. Bei diesen ist oft
                    entweder an den Beträgen oder an den Zinssätzen etwas faul. Außerdem müssen Privatpersonen auf
                    Kreditzinsen eine Mehrwertsteuer verrechnen und ihre Zinseinkünfte versteuern, was die{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    automatisch erhöht.
                </p>
                <hr />

                <h2>So gehst du beim Kredit auf Nummer sicher</h2>
                <p>
                    Damit du bei deinem nächsten Kreditantrag bessere Chancen hast, solltest du unbedingt all deine
                    Optionen durchdenken und dir einen Überblick verschaffen. Und am besten ist es natürlich, wenn du
                    das nicht ganz alleine machen musst, sondern dir erfahrene Berater:innen zur Seite stehen. Wir
                    helfen dir gerne dabei. Ein unverbindliches Beratungsgespräch mit unseren Expert:innen ist immer
                    möglich.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"kreditAbgelehnt"}></BreadcrumbList>
            <ArticleStructuredData page={"kreditAbgelehnt"} heading={"Kredit abgelehnt? – Woran kann es liegen?"} />
        </Layout>
    );
};

export default KreditAbgelehnt;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kredit-abgelehnt", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
